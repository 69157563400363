.modal-sm {
  max-width: 300px;
}
.modal-lg {
  max-width: 800px;
}
.modal-xl {
  max-width: 1140px;
}

.modal-header .btn-close {
}
