/* .input-daterange .input-group-addon{
	min-width: 40px;
} */

.react-datepicker-wrapper {
  flex: 1 1 auto;
  width: 1%;
}

.react-datepicker__triangle {
  left: -20px !important;
}
