.text-link {
    text-decoration: underline;
    color: #1c84c6;
}

.login-container {
    background-image: url("./assets/images/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
}