.col-checkbox,
.col-running,
.col-action,
.col-active {
  text-align: center;
}
.col-checkbox {
  /*width: 10%;*/
}
.col-running {
  /*width: 10%;*/
}
.col-action {
}
.col-active {
}

.table-header th.sortable {
  background-image: url("../../../assets/images/order-default.png");
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 20px;
}

.table-header th.sortable.asc {
  background-image: url("../../../assets/images/order-asc.png");
}

.table-header th.sortable.desc {
  background-image: url("../../../assets/images/order-desc.png");
}

.input-group-btn .btn {
  margin-right: 0 !important;
}

.table-filter {
  background-color: #eaeaea;
  display: none;
}

#toolbar {
  margin-top: 10px;
}

#page-amount-input {
  display: inline-block;
  width: 80px;
}

.search-criteria-container {
  position: relative;
}

.search-criteria {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #1ab394;
  z-index: 3;
}

.search-criteria .toggle-advance-search {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 0 8px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: 0.8s all;
}

.search-criteria .toggle-advance-search:hover {
  border: 1px solid #ccc;
  background: rgba(152, 103, 120, 0.5);
  color: #fff;
}

.search-criteria > .form-control {
  border-right: 0;
  border: none;
}

.search-criteria
  > .form-control:focus
  + .input-group-append
  > .input-group-text {
}

.search-criteria .input-group-append .input-group-text {
  width: 50px;
  border: none;
  background: none;
}

.advance-search-container {
  background: #fff;
  -webkit-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.75);
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 2;
  width: 100%;
  display: none;
}

.advance-search-container.show {
  display: inline-block;
}

.search-criteria-container .filter-by-container .remove {
  cursor: pointer;
  margin-left: 5px;
}

.autoComplete_wrapper {
  flex: 1 1 auto;
}
